import React, { Component } from 'react'
import * as Subheader from '../services/Subheader'
import * as Service from '../services/Create'
import './Home.css'





export default class Clientes extends Component {
  
        constructor(props) {
        super(props)
  
        this.state = {
            Form: "",
            Result: "",
            TableList: "",
           
        }
      
    
    }
   

    componentDidMount() {
        this.setState({Form: <Service.Controller action="showForm" page="Clientes" />})
   
    }


    render() {
        
        return (
            
            <div className="container mt-5 text-start">
                <div className="row">
                    <div className='col-lg-6 col-sm-12'>
                    {this.state.Form}
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <div className="card">
                            <div className="card-body">
                        <h5>Cliente de <span className='text-danger'>Alto Riesgo </span></h5>
                        <p><span>Nombre</span>:Rosendo Yanez</p>
                        <p><span>Cedula</span>:001-1647572-3</p>
                        <p><span>Ocupación</span>: ABOGADO</p>
                        <br/>
                        <h6>DEBIDA DILIGENCIA</h6>
                        <p>Tipo: Ampliada</p>
                        Solicitar:
                        <ul>
                            <li>Carta de Trabajo</li>
                            <li>Carta de referencia bancaria</li>
                            
                        </ul>
                        <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Adjuntar documentación</label>
                <div className="col-sm-8">
                     <input className="form-control" type="file" id="formFile" />
                </div>
            </div>
                        </div>
                        </div>
                       
                    {this.state.Result}
                    </div>

                </div>

                        
            </div>
           


        )
    }
}
