import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from './containers/Login'
import AppliedRoute from './componets/AppliedRoute'




export default ({ childProps }) => 

   
    <Switch>
        <AppliedRoute component={Login}  props={childProps} />
    </Switch>
