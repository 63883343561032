import React, { Component } from 'react'
import * as Subheader from '../services/Subheader'
import * as Service from '../services/Create'
import './Home.css'





export default class Clientes extends Component {
  
        constructor(props) {
        super(props)
  
        this.state = {
            Form: "",
            Result: "",
            TableList: "",
           
        }
      
    
    }
   

    componentDidMount() {
        this.setState({Form: <Service.Controller action="showForm" page="Reportes" />})
   
    }


    render() {
        
        return (
            
            <div className="container mt-5 text-start">
                <div className="row">
                    <div className='col-lg-12 col-sm-12'>
                    {this.state.Form}
                    </div>
                  
                        </div>
                        <br/>
                        <br/>
                       <table class="table d-none">
  <thead>
    <tr>
    
      <th scope="col">Nombre/Razon Social</th>
      <th scope="col">CEDULA/RNC</th>
      <th scope="col">Riesgo</th>
    </tr>
  </thead>
  <tbody>
    <tr>
    
      <td>Rosendo Yanez</td>
      <td>001-29323-3</td>
      <td>Alto</td>
    </tr>
    <tr>
     
      <td>Jacob</td>
      <td>234234-4324</td>
      <td>Bajo</td>
    </tr>
    <tr>
     
      <td>Larry</td>
      <td>093284093284</td>
      <td>Bajo</td>
    </tr>
  </tbody>
</table>
                    {this.state.Result}
                   

               

                        
            </div>
           


        )
    }
}
