import React, { Component,Fragment } from 'react'
import Routes from './Router'
import SignOutNavigation from './signOutRouter'
import { withRouter } from 'react-router-dom'
import ApplicationHeader from './componets/ApplicationHeader'

import './App.css'

class App extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            isAuthenticated: false,
            isAuthenticating: false,
           
        }
    
    }
  userHasAuthenticated = authenticated => {
      this.setState({ isAuthenticated: authenticated })
  }
  async componentDidMount() {
  
  
      try {
          const USER_SESSION = sessionStorage.getItem("ICUSERSESSION")
          if (!USER_SESSION) throw "Session expired, please sign in again."
           this.userHasAuthenticated(true)
          
      
      }
      catch(e) {
              console.log(e)
              this.userHasAuthenticated(false)
              this.props.history.push('/login')
          
      }

      this.setState({ isAuthenticating: false })

    }
  

  

  render() {
      const childProps = {
          isAuthenticated: this.state.isAuthenticated,
          userHasAuthenticated: this.userHasAuthenticated,
         
      }

      const loginUI = <Fragment>
   
          <div className="row">
              <div className="container">
                  <SignOutNavigation childProps={childProps} />
              </div>
          </div>
      </Fragment>

      const LoggedUI = <Fragment>
       
          <div className="container">
              <div className="row">
                           <ApplicationHeader childProps={childProps}  />

                 </div>
                
                      <div className="row container">

                      <Routes childProps={childProps} />
 
                      </div>

                


             
          </div>


      </Fragment>
    
      return (
      
          <div className="App row">
              {!this.state.isAuthenticated ? loginUI : LoggedUI }
         
          </div>
  
  
      )
    
    
  }
  
}




export default withRouter(App)
