import React, { Component } from 'react'
import './Home.css'


export default class Home extends Component {
  
    componentDidMount() {
   
    }

    render() {
        return (
            <div className="container-fluid">
        
            </div>
    


        )
    }
}
