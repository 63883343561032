import React, { Component } from 'react'
import {Card,Button,Form} from 'react-bootstrap'
import * as Auth from '../services/Auth'

import './Home.css'

    


export default class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: ''
        }
    }
  
    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0
    }

  handleChange = (event) => {
      this.setState({
          [event.target.id]: event.target.value
      })
  }
  
  formSubmited = async event => {
   
      const errorWrapper = document.getElementById('errorWrapper')
      const submitBtn = document.getElementById('submitBtn')
      const loading = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>'
      errorWrapper.innerHTML = loading
      submitBtn.classList.add("d-none") 
      event.preventDefault()
      
       try {
      Auth.signIn(this.state.email, this.state.password)
     .then(data => {
     if(data.error)  throw data.message 
    
     const { id,usuario,nombre,empresa_id,rol_id } = data.message
        sessionStorage.setItem(
            'ICUSERSESSION',
            JSON.stringify({
                USERID: id,
                USERNAME: usuario,
                COMPANYID: empresa_id,
                ROLEID: rol_id,
                USERFULLNAME: nombre

            }))
             window.location.assign('/')

     })
     .catch(err => {
       let error = '<div class="alert alert-warning alert-dismissible fade show" role="alert">' + err +'</div>'
       errorWrapper.innerHTML = error
       submitBtn.classList.remove("d-none")
    })

              

       } catch (e) {

        console.error(e)
     }
   }


  render() {
      

      return (
          <div className="Home mt-5">
              <div className="lander mt-5">
                  <div className="col-lg-4 col-sm-12 mx-auto"  style={{"marginTop" : "7.328%"}}>
                      <Card className="shadow-sm animated fadeIn p-3 ">
                              <Card.Img className="mx-5 my-3" variant="top"  alt="logo" src={require('../incompliance_long.png')}  />
                          <Card.Body>
    
                             
                                  <Form onSubmit={this.formSubmited}>
                                      <Form.Group controlId="email" className="mb-3 mt-2" >
                                          <Form.Control 
                                              autoComplete = "username"
                                              placeholder="Usuario"
                                              autoFocus
                                              type="text"
                                              size="lg"
                                              value={this.state.email}
                                              onChange={this.handleChange}
                                          />
                                      </Form.Group>

                                      <Form.Group controlId="password" className="mb-3">
                                          <Form.Control
                                              autoComplete="current-password"
                                              value={this.state.password}
                                              onChange={this.handleChange}
                                              type="password"
                                              size="lg"
                                              placeholder="Contraseña" />
                                      </Form.Group>
                                      <div id="errorWrapper"></div>
                                      <div id="submitBtn" className="mt-2">
                                          <Button 
                                          style={{"width":"100%"}}
                                              disabled={!this.validateForm()}
                                              type="submit"
                                              variant="outline-primary text-uppercase btn-lg mt-3">
                                              Entrar
                                          </Button>
                                      </div>
                                  </Form>
                            

                             

          
   
                          </Card.Body>
                      </Card>
                  </div>
              </div>
          </div>
      )
      
  }

}
