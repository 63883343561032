import React, { Component } from 'react'
import * as Subheader from '../services/Subheader'
import './Home.css'


export default class Respuestas extends Component {
  
    constructor(props) {
        super(props)
  
        this.state = {
            Application: "",
           
        }
      
    
    }
   
  
    componentDidMount() {
        this.setState({Application: <Subheader.setHeader page="Respuestas" />})
   
    }

    render() {
        
        return (
            
            <div className="container mt-5 text-left">
                <div className="col-11">
                    {this.state.Application}
                    {/* {this.state.GetData} */}
                </div>
            </div>
           


        )
    }
}
