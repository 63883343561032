import React, { Component } from 'react'
import '../containers/Home.css'
import { NavLink } from 'react-router-dom'





export default class ApplicationHeader extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            userEmail: '',
            userGroup: ''
          
        }
        
    }
    handleLogout = async  event => {
        sessionStorage.removeItem('ICUSERSESSION')
        window.location.assign('/')
    }
      
      
    render() {
        const menu = ["Clientes", "Reportes", "Alertas","Usuarios"];
        return (
<div className="container-fluid" style={{'width': '100%'}}>
    <nav  className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid" >
    <a className="navbar-brand" href="#">   <img style={{'width': '310px'}} src={require('../incompliance_long.png')} /></a>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
       
      </ul>
      <ul className="navbar-nav">
           {
           menu.map((val,index) =>   
            <li className="nav-item mx-2" key={index}>
              <NavLink to={val}><button  className="btn btn-outline-primary m-1" type="button">{val} </button></NavLink>
              </li>
           
           )
           
           }
          <li className="nav-item mx-2"><button  onClick={this.handleLogout} className="btn btn-outline-danger m-1" type="button">Salir </button></li>

       
   
      </ul>
    </div>
  </div>
</nav>
</div> 
        )}
}
