import React, { useState,Fragment } from 'react';
import  { API }  from '../config';
import axios from 'axios';
import Select from 'react-select';
import GetOptions from '../dataLayer/Data';


function CreateForm(page){


const clientType = (e) =>  {

const corporativos = [].slice.call(document.getElementsByClassName("corporativos"))
const individual = [].slice.call(document.getElementsByClassName("individual"))
const FormOptions = document.getElementsByClassName("FormOptions")
const clientTypeId = e.value;
clientTypeId > 0 ? FormOptions[0].classList.remove("d-none", "fadeOut") : FormOptions[0].classList.add("d-none","fadeOut");
clientTypeId == 0 && individual.map((element,index) => {  individual[index].classList.add("d-none") }) && corporativos.map((element,index) => {  corporativos[index].classList.add("d-none") })
clientTypeId == 1 && individual.map((element,index) => {  individual[index].classList.toggle("d-none") }) &&  corporativos.map((element,index) => {  corporativos[index].classList.add("d-none") })
clientTypeId == 2 && corporativos.map((element,index) => {  corporativos[index].classList.toggle("d-none") }) && individual.map((element,index) => {  individual[index].classList.add("d-none") })
 appendTextToDom("tipoCliente", e)
}


const  dniFields = (elem) => {
appendTextToDom("tipo_de_identificacion", elem)
    const form = document.getElementById("dniName")
    elem.value == 3 ? form.classList.remove("d-none") :form.classList.add("d-none")
    
}
const uploadImage = (e) => {
    const formData = new FormData();
    formData.append('image', document.getElementsByName("file")[0].files[0]);
    const url = API.URL;
    axios.post(url, formData, {})
        .then(res => {
            console.log(res.data);
        })

}

const appendTextToDom = (fieldName,e) => {
    const id = fieldName + "TextForm"
    const hiddenForm = document.getElementById(id)
    hiddenForm.innerHTML = "<input name='" + fieldName + "Text' value='" + e.label + "' type='hidden' />"
    
}
const  insert = (e) => {

    const getFormData = document.getElementById(e.target.name)
    
   const formData = new FormData(getFormData);
    
   for (const [key, value] of formData) {
 console.log(key)
}
   formData.append('action', "CreateClient");
   const url = API.URL;
   axios.post(url, formData, {})
       .then(res => {
           console.log(res.data);
       })
}



const pageFrom = page.page
const from = pageFrom.replace(/ /g, '');
let form
const fields = {"data":{"Usuarios":[
                            {"label":"Usuario","name":"usuario","type":"text"},
                            {"label":"Contraseña","name":"contrasena","type":"password"},
                            {"label":"Correo","name":"correo","type":"emaiil"},
                            {"label":"Empresa","name":"empresa","type":"text"},
                            {"label":"Rol","name":"rol","type":"select","options":[
                                {"value":1,"label":"Admin"},
                                {"value":2,"label":"Empresa"},
                                {"value":3,"label":"Usuario"},
                            ]},
                           
                            
                          ]},"Clientes": [
                               {"label":"Tipo de cliente","name":"tipoCliente","type":"select","options":[
                                {"value":0,"label":""},
                                {"value":1,"label":"Individual"},
                                {"value":2,"label":"Corporativo"},
                            ]},
                            {"function":"dniFields","tipoCliente":"individual","label":"Tipo de identificación","name":"tipo_de_identificacion_individual","type":"select","options":[
                                {"value":0,"label":""},
                                {"value":1,"label":"Cedula"},
                                {"value":2,"label":"Licencia"},
                                 {"value":3,"label":"Otro"},
                            ]},
                             {"function":"dniFields","tipoCliente":"corporativos","label":"Tipo de identificación","name":"tipo_de_identificacion_corperativo","type":"select","options":[
                                {"value":0,"label":""},
                                {"value":1,"label":"RNC"},
                                {"value":2,"label":"Registro Mercantil"},
                                 {"value":3,"label":"Otro"},
                            ]},
                          ]}


switch(from){
   case "Usuarios":
                     
                     form =
                     <div className='row'>
                         <h5 className="card-title"><button onClick={() => setView(<Controller action="init" page={page.page} />)} type="button" className="btn btn-outline-danger closeIt float-end" data-dismiss="alert" aria-label="Close"></button></h5>
                   
                     <form id={from} >
                    {fields['data']['Usuarios'].map(form => 
                        form.type == "select" ? 
                         <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                <Select Placeholder="Selecciona" name={form.name} className="selectWithSearch" id={form.name} options={form["options"]}/>
                              
                            </div>
                        </div>
                        :
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                <input name={form.name} type={form.type} className="form-control" id={form.name} />
                            </div>
                        </div>
                    )}
                          <a name={from} href="#" onClick={(e) => insert(e)} className="card-link btn" style={{"backgroundColor": "#f1824f", "color":"white"}} >Crear Usuario</a>
                      </form>
                      </div>
                  break

          case "Clientes":
            form = <form id={from} >
                  <h4>CONOZCA A SU CLIENTE</h4>
                  <hr></hr>
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{fields['Clientes'][0].label}</label>
                            <div className="col-sm-8">
                                <Select placeholder={"Selecciona " + fields['Clientes'][0].label} onChange={clientType} name={fields['Clientes'][0].name} className="selectWithSearch" options={fields['Clientes'][0]["options"]}/>
                              <div id={fields['Clientes'][0].name + "TextForm"}  className="d-none"></div>
                            </div>
                        </div>
                        <div className='d-none FormOptions animated fadeIn'>    

                     {
                         fields['Clientes'].slice(1).map(form =>
                        form.type == "select" ? 
                         <div className={"row mb-3 d-none " + form.tipoCliente}>
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                {form.function ? 
                                <Select placeholder={"Selecciona " + form.label} onChange={dniFields} name={form.name} className="selectWithSearch" id={form.name} options={form["options"]}/>
                                : 
                                 <Select placeholder={"Selecciona " + form.label} name={form.name} className="selectWithSearch" id={form.name} options={form["options"]}/>
                                }
                               
                            </div>
                        </div>
                        :
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                <input name={form.name} type={form.type} className="form-control" id={form.name} />
                            </div>
                        </div>
                    )}
                     
     <div id="tipo_de_identificacionTextForm" className="d-none"></div>
<div id="hiddenForm" ></div>



<div className="row mb-3 d-none"  id="dniName">
                <label for="ingresos" className="col-sm-4 col-form-label">Nombre de identificación</label>
                <div className="col-sm-8">
                    <input name="tipo_de_identificacion_otro" className="form-control" type="text" id="tipo_de_identificacion_otro" />
                </div>
            </div>

<div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Numeración de documento</label>
                <div className="col-sm-8">
                     <input name="numeracion_de_documento" className="form-control" type="text" id="numeracion_de_documento" />
                </div>
            </div>

            <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Foto del documento</label>
                <div className="col-sm-8">
                    <input className="form-control" name="foto_tipo_de_identificacion" type="file" id="foto_tipo_de_identificacion" />
                </div>
            </div>

<div className="row mb-3 individual d-none">
                <label for="ingresos" className="col-sm-4 col-form-label">Nombre</label>
                <div className="col-sm-8">
                    <input name="nombre_individual" className="form-control" type="text" id="formFile" />
                </div>
            </div>
            <div className="row mb-3 corporativo d-none">
                <label for="ingresos" className="col-sm-4 col-form-label">Razon social</label>
                <div className="col-sm-8">
                    <input name="nombre_corporativo" className="form-control" type="text" id="formFile" />
                </div>
            </div>
            <div className="row mb-3 individual d-none" id="dni">
                <label for="nombre" className="col-sm-4 col-form-label">Estado civil</label>
                <div className="col-sm-8">
                      <Select onChange={(e) => appendTextToDom("estado_civil",e)} 
                      placeholder="Selecciona estado_civil"
                       name="estado_civil" 
                       className="selectWithSearch" 
                       id="estado_civil"
                        options={GetOptions("estado_civil")}/>
                    
                    <div id="estado_civilTextForm" className="d-none"></div>
                   
                </div>
            </div>

            <div className="row mb-3 individual d-none">
                <label for="ingresos" className="col-sm-4 col-form-label">Fecha de nacimiento</label>
                <div className="col-sm-8">
                    <input name="fecha_de_nacimiento" className="form-control" type="date" id="formFile" />
                </div>
            </div>
            <div className="row mb-3 corporativos d-none">
                <label for="ingresos" className="col-sm-4 col-form-label">Fecha de constitución</label>
                <div className="col-sm-8">
                    <input name="fecha_de_constitucion" className="form-control" type="date" id="formFile" />
                </div>
            </div>
            <div className="row mb-3 corporativos d-none">
                <label for="ingresos" className="col-sm-4 col-form-label">Fecha de inicio de operaciones</label>
                <div className="col-sm-8">
                    <input name="fecha_de_incio_de_operaciones" className="form-control" type="date" id="formFile" />
                </div>
            </div>


            <div className="row mb-3">
                <label for="correo" className="col-sm-4 col-form-label">Teléfono</label>
                <div className="col-sm-8">
                    <input name='telefono' type="text" className="form-control" id="correo" />
                </div>
            </div>

            <div className="row mb-3">
                <label for="correo" className="col-sm-4 col-form-label">Dirección</label>
                <div className="col-sm-8">
                    <textarea name="direccion" className="form-control" id="correo"></textarea>
                </div>
            </div>
            <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Prueba de dirección</label>
                <div className="col-sm-8">
                    <input name="foto_direccion" className="form-control" type="file" id="formFile" />
                </div>
            </div>

            <div className="row mb-3">
                <label for="correo" className="col-sm-4 col-form-label">Correo electrónico</label>
                <div className="col-sm-8">
                    <input name="correo" type="text" className="form-control" id="correo" />
                </div>
            </div>
               <div className="row mb-3 corporativos d-none">
                <label for="correo" className="col-sm-4 col-form-label">Representante</label>
                <div className="col-sm-8">
                    <input name="representante" type="text" className="form-control" id="representante" />
                </div>
            </div>
              <div className="row mb-3  individual d-none">
                <label for="correo" className="col-sm-4 col-form-label">Ocupación</label>
                <div className="col-sm-8">
                      <Select onChange={(e) => appendTextToDom("ocupacion",e)}  
                      placeholder="Selecciona ocupacion"
                       name="ocupacion" 
                       className="selectWithSearch" 
                       id="ocupacion"
                        options={GetOptions("ocupacion")}/>
                    <div id="ocupacionTextForm" className="d-none"></div>
                </div>
            </div>

            <div className="row mb-3" id="dni">
                <label for="nombre" className="col-sm-4 col-form-label">Actividad económica</label>
                <div className="col-sm-8">
                    
                       <Select onChange={(e) => appendTextToDom("actividad_economica_individual",e)} 
                       placeholder = "Selecciona actividad economica"
                       name = "actividad_economica"
                       className = "selectWithSearch"
                       id = "actividad_economica"
                       options = { GetOptions("actividad_economica_individual") }
                       />
                          <div id="actividad_economica_individualTextForm" className="d-none"></div>
                </div>
            </div>



            <div className="row mb-3 individual d-none">
                <label for="correo" className="col-sm-4 col-form-label">Situación laboral</label>
                <div className="col-sm-8">
                          <Select onChange={(e) => appendTextToDom("situacion_laboral",e)} 
                          placeholder = "Selecciona Situación laboral"
                          name = "situacion_laboral"
                          className = "selectWithSearch"
                          id = "situacion_laboral"
                          options = {
                              GetOptions("situacion_laboral")
                          }
                          />
                   <div id="situacion_laboralTextForm" className="d-none"></div>
                </div>
            </div>

            <div className="row mb-3" id="dni">
                <label for="nombre" className="col-sm-4 col-form-label">Ingresos mensuales</label>
                <div className="col-sm-8">
                    <Select onChange={(e) => appendTextToDom("ingresos_mensuales",e)} 
                          placeholder = "Selecciona ingresos mensuales"
                          name = "ingresos_mensuales"
                          className = "selectWithSearch"
                          id = "ingresos_mensuales"
                          options = { GetOptions("ingresos_mensuales") }
                          />
                          <div id="ingresos_mensualesTextForm" className="d-none"></div>
               
                   
                </div>
            </div>
            <div className="row mb-3 individual d-none">
                <label for="correo" className="col-sm-4 col-form-label">País de residencia</label>
                <div className="col-sm-8">
                    <Select onChange={(e) => appendTextToDom("pais_de_residencia",e)} 
                          placeholder = "Selecciona pais de residencia "
                          name = "pais_de_residencia"
                          className = "selectWithSearch"
                          id = "pais_de_residencia"
                          options = { GetOptions("pais_de_residencia") }
                          />
                 <div id="pais_de_residenciaTextForm" className="d-none"></div>
                </div>
            </div>
             <div className="row mb-3 individual d-none">
                <label for="correo" className="col-sm-4 col-form-label">Nacionalidad</label>
                <div className="col-sm-8">

                      <Select onChange={(e) => appendTextToDom("nacionalidad",e)} 
                          placeholder = "Selecciona pais de nacionalidad "
                          name = "nacionalidad"
                          className = "selectWithSearch"
                          id = "nacionalidad"
                          options = { GetOptions("pais_de_residencia") }
                          />
                   <div id="nacionalidadTextForm" className="d-none"></div>
                </div>
            </div>
              <div className="row mb-3 corporativos d-none">
                <label for="correo" className="col-sm-4 col-form-label">País de constitución</label>
                <div className="col-sm-8">
                       <Select onChange={(e) => appendTextToDom("pais_de_constitucion",e)} 
                          placeholder = "Selecciona pais de constitución "
                          name = "pais_de_constitucion"
                          className = "selectWithSearch"
                          id = "pais_de_constitucion"
                          options = { GetOptions("pais_de_residencia") }
                          />
                            <div id="pais_de_constitucionTextForm" className="d-none"></div>
                          </div>
            </div>
            <div className="row mb-3 corporativos d-none">
                <label for="correo" className="col-sm-4 col-form-label">País de operaciones</label>
                <div className="col-sm-8">
                    <Select onChange={(e) => appendTextToDom("pais_de_operaciones",e)} 
                          placeholder = "Selecciona pais de operaciones "
                          name = "pais_de_operaciones"
                          className = "selectWithSearch"
                          id = "pais_de_operaciones"
                          options = { GetOptions("pais_de_residencia") }
                          />
                <div id="pais_de_operacionesTextForm" className="d-none"></div>
                </div>
            </div>
             <div className="row mb-3">
                <label for="correo" className="col-sm-4 col-form-label">Provincia</label>
                <div className="col-sm-8">
                    <Select onChange={(e) => appendTextToDom("provincia",e)} 
                          placeholder = "Selecciona provincia "
                          name = "provincia"
                          className = "selectWithSearch"
                          id = "provincia"
                          options = { GetOptions("provincia") }
                          />
                    <div id="provinciaTextForm" className="d-none"></div>
                </div>
            </div>
            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">PEP</label>
                <div className="col-sm-8">
                      <Select onChange={(e) => appendTextToDom("pep",e)} 
                          placeholder = "Selecciona provincia "
                          name = "pep"
                          className = "selectWithSearch"
                          id = "pep"
                          options = { GetOptions("boolean") }
                          />
    <div id="pepTextForm" className="d-none"></div>
                </div>
            </div>
            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Tipo de inicial</label>
                <div className="col-sm-8">
                    <select name="tipo_de_incial"  id="producto" className="form-select selectWithSearch p-2">
                        <option></option>
                        <option value="vehiculo">Vehículo</option>
                        <option value="efectivo">Monto en efectivo</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Forma de pago de inicial</label>
                <div className="col-sm-8">

                     <Select onChange={(e) => appendTextToDom("forma_de_pago_de_incial",e)} 
                          placeholder = "Selecciona Pago Inicial "
                          name = "forma_de_pago_de_incial"
                          className = "selectWithSearch"
                          id = "forma_de_pago_de_incial"
                          options = { GetOptions("forma_de_pago_de_incial") }
                          />
                      <div id="forma_de_pago_de_incialTextForm" className="d-none"></div>
                </div>
            </div>
            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Monto de inicial</label>
                <div className="col-sm-8">

                     <Select onChange={(e) => appendTextToDom("monto_de_incial",e)} 
                          placeholder = "Selecciona Monto Inicial "
                          name = "monto_de_incial"
                          className = "selectWithSearch"
                          id = "monto_de_incial"
                          options = {
                              GetOptions("monto_de_incial")
                          }
                          />
                    <div id="monto_de_incialTextForm" className="d-none"></div>
                </div>
            </div>

            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Moneda pago inicial</label>
                <div className="col-sm-8">
                     <Select onChange={(e) => appendTextToDom("moneda_de_pago_inicial",e)}
                     placeholder = "Selecciona moneda_de_pago_inicial "
                     name = "moneda_de_pago_inicial"
                     className = "selectWithSearch"
                     id = "moneda_de_pago_inicial"
                     options = {
                         GetOptions("moneda")
                     }
                     />
                       <div id="moneda_de_pago_inicialTextForm" className="d-none"></div>
                </div>
            </div>

             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Valor total del vehículo</label>
                <div className="col-sm-8">
                     <Select onChange={(e) => appendTextToDom("monto_de_incial",e)}
                          placeholder = "Selecciona valor_total_del_vehiculo "
                          name = "valor_total_del_vehiculo"
                          className = "selectWithSearch"
                          id = "valor_total_del_vehiculo"
                          options = { GetOptions("valor_total_del_vehiculo") }
                          />
                   <div id="valor_total_del_vehiculoTextForm" className="d-none"></div>
                </div>
            </div>
            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Moneda pago valor total</label>
                <div className="col-sm-8">
                     <Select onChange={(e) => appendTextToDom("moneda_pago_valor_total",e)}
                     placeholder = "Selecciona moneda_pago_valor_total "
                     name = "moneda_pago_valor_total"
                     className = "selectWithSearch"
                     id = "moneda_pago_valor_total"
                     options = {
                         GetOptions("moneda")
                     }
                     />
                      <div id="moneda_pago_valor_totalTextForm" className="d-none"></div>
                </div>
            </div>

 <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Marca del vehículo</label>
                <div className="col-sm-8">
                        <Select onChange={(e) => appendTextToDom("marca_del_vehiculo",e)}
                          placeholder = "Selecciona marca_del_vehiculo "
                          name = "marca_del_vehiculo"
                          className = "selectWithSearch"
                          id = "marca_del_vehiculo"
                          options = { GetOptions("marca_del_vehiculo") }
                          />
   <div id="marca_del_vehiculoTextForm" className="d-none"></div>
                </div>
            </div>
             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Canal de venta</label>
                <div className="col-sm-8">
                       <Select onChange={(e) => appendTextToDom("canal_de_venta",e)}
                          placeholder = "Selecciona canal_de_venta "
                          name = "canal_de_venta"
                          className = "selectWithSearch"
                          id = "canal_de_venta"
                          options = { GetOptions("canal_de_venta") }
                          />
                 <div id="canal_de_ventaTextForm" className="d-none"></div>

                </div>
            </div>
             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Financiamiento</label>
                <div className="col-sm-8">
                 <Select onChange={(e) => appendTextToDom("financiamiento",e)}
                          placeholder = "Selecciona financiamiento "
                          name = "financiamiento"
                          className = "selectWithSearch"
                          id = "financiamiento"
                          options = { GetOptions("financiamiento") }
                          />
             <div id="financiamientoTextForm" className="d-none"></div>
                </div>
            </div>

            <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Moneda pago valor total</label>
                <div className="col-sm-8">
                     <Select 
                          placeholder = "Selecciona moneda pago "
                          name = "moneda_pago_valor_total_financiamiento"
                          className = "selectWithSearch"
                          id = "moneda_pago_valor_total_financiamiento"
                          options = { GetOptions("moneda") }
                          />
                      <div id="financiamientoTextForm" className="d-none"></div>
                </div>
            </div>

             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Forma de pago de financiamiento</label>
                <div className="col-sm-8">
                    
                    <Select onChange={(e) => appendTextToDom("forma_pago_financiamiento",e)}
                          placeholder = "Selecciona forma_pago_financiamiento "
                          name = "forma_pago_financiamiento"
                          className = "selectWithSearch"
                          id = "forma_pago_financiamiento"
                          options = { GetOptions("forma_pago_financiamiento") }
                          />
                     <div id="forma_pago_financiamientoTextForm" className="d-none"></div>
                </div>
            </div>
 <div className="row mb-3 natural">
                <label  className="col-sm-4 col-form-label">Noticias Negativas</label>
                <div className="col-sm-8">
                      <Select 
                          placeholder = "Selecciona noticias_negativas "
                          name = "noticias_negativas"
                          className = "selectWithSearch"
                          id = "noticias_negativas"
                          options = { GetOptions("boolean") }
                          />
                    <div id="noticias_negativasTextForm" className="d-none"></div>
                </div>
            </div>
            <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Foto Noticias Negativas</label>
                <div className="col-sm-8">
                    <input name="foto_noticias_negativas" className="form-control" type="file" id="fotos_noticias_negativas" />
                </div>
            </div>
             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Coincidencias en OFAC</label>
                <div className="col-sm-8">
                      <Select onChange={(e) => appendTextToDom("ofac",e)}
                          placeholder = "Selecciona Coincidencias en OFAC "
                          name = "ofac"
                          className = "selectWithSearch"
                          id = "ofac"
                          options = { GetOptions("boolean") }
                          />
                  
                      <div id="ofacTextForm" className="d-none"></div>
                   
                </div>
            </div>
            <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Foto Coincidencias en OFAC</label>
                <div className="col-sm-8">
                    <input name="foto_ofac" className="form-control" type="file" id="formFile" />
                </div>
            </div>
             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Coincidencias en Onu</label>
                <div className="col-sm-8">
                    <Select onChange={(e) => appendTextToDom("onu",e)}
                          placeholder = "Selecciona Coincidencias en Onu "
                          name = "onu"
                          className = "selectWithSearch"
                          id = "onu"
                          options = { GetOptions("boolean") }
                          />
 <div id="onuTextForm" className="d-none"></div>
                     
                   
                </div>
            </div>
            <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Foto Coincidencias en Onu</label>
                <div className="col-sm-8">
                    <input name="foto_onu" className="form-control" type="file" id="formFile" />
                </div>
            </div>
             <div className="row mb-3 natural">
                <label for="correo" className="col-sm-4 col-form-label">Coincidencias en INTERPOL</label>
                <div className="col-sm-8">
                      <Select onChange={(e) => appendTextToDom("INTERPOL",e)}
                          placeholder = "Selecciona Coincidencias en INTERPOL "
                          name = "INTERPOL"
                          className = "selectWithSearch"
                          id = "INTERPOL"
                          options = { GetOptions("boolean") }
                          />
                 <div id="INTERPOLTextForm" className="d-none"></div>
                </div>
            </div>
            <div className="row mb-3">
                <label for="ingresos" className="col-sm-4 col-form-label">Foto Coincidencias en INTERPOL</label>
                <div className="col-sm-8">
                    <input name="foto_INTERPOL" className="form-control" type="file" id="formFile" />
                </div>
            </div>

                  <a name={from} href="Clientes/created"  className="card-link btn" style={{"backgroundColor": "#f1824f", "color":"white"}} >Crear cliente</a>
                 
                        </div>
                   </form>
                  
          break;
           case "Reportes":
            form = <form id={from} className="row">
             
  <div className="col-md-4">
                <label for="nombre" class="form-label">Nombre/Razon Comercial</label>
                <input type="text" class="form-control" id="nombre" />
                </div>
                 <div className="col-md-4">
                <label for="rnc" class="form-label">RNC/Cedula</label>
                <input type="text" class="form-control" id="rnc" />
                </div>
                 <div className="col-md-4">
                <label for="rnc" class="form-label">Riesgo</label>
                  <Select
                          placeholder = "Selecciona Riesgo"
                          name = "riesgo"
                          className = "selectWithSearch"
                          id = "riesgo"
                          options = { GetOptions("riesgo") }
                          />
                           </div>
                <div className='row'>
 <div className="col-md-4">
                     <label for="dateFrom" className="form-label">Desde</label>
    <input type="date" className="form-control col" id="dateFrom" />
  
  </div>
  
  <div className="col-md-4">
    <label for="dateTo" className="form-label">Hasta</label>
    <input type="date" className="form-control" id="dateTo" />
  </div>
  </div>
  <div className="col-md-4">
  <a href="Reporte/Results" className="mt-4 btn btn-outline-primary">Buscar</a>
</div>



                        </form>
                  
 
 
                  break;
               
      
case "Alertas":
            form = 
<div class="card">
  <h5 class="card-header text-white bg-danger">Inicial superior a 500,000</h5>
  <div class="card-body row">
    <div class="col-5">
    <h5 class="card-title">Rosendo Yanez</h5>
    <p class="card-text">001-16474727</p>
    <p class="card-text">US$ 3,000.00</p>
</div>
<div class="col-7">
    <div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Comentario</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div>
    <a href="#" class="btn btn-primary">Aceptar</a>
    <a href="#" class="mx-2 btn btn-danger">Declinar</a>
</div>
  </div>
</div>
 break;
                }
const sform = <form>
                <div class="mb-3">
                <label for="nombre" class="form-label">Nombre</label>
                <input type="text" class="form-control" id="nombre" />
                </div>
                 <div class="mb-3">
                <label for="rnc" class="form-label">RNC</label>
                <input type="text" class="form-control" id="rnc" />
                </div>
                 <div class="mb-3">
                <label for="rnc" class="form-label">RNC</label>
                <input type="text" class="form-control" id="rnc" />
                </div>
                <div class="mb-3">
                <label for="rnc" class="form-label">Tipo de sujeto obligado</label>
                <input type="text" class="form-control" id="rnc" />
                </div>
                
                <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Direccion</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>

               
                </form>


 const create = (e)  => {
  const elForm = document.getElementById(e.target.name)
  let formData = new FormData(elForm);
  let form = {};
  for (let pair of formData.entries()) { form[pair[0]] = pair[1]  }

  const data = `action=create&module=${e.target.name}&data=` + JSON.stringify(form)
   axios.post(API.URL, data)
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}
const formDom =  <Fragment>
                        <div id="formWrapper">
                            <div className="card" id="formBody">
                            <div className="card-body">
                                    {form}
                                </div>
                            </div>
                        </div>   
                    </Fragment>
const [view, setView] = useState(formDom);

 return( view )

                }


                function Controller(props){
                    let dom;
                     if(props.action == "init"){
                        dom = <a name={props.page} href="#create" onClick={() => setView(<CreateForm action="showForm" page={props.page} />)} className="card-link btn orangeICGS">Crear {props.page}</a>
                    }else{
                        dom = <CreateForm action="showForm" page={props.page} />
             
              
            }
                    const [view, setView] = useState(dom);
                    
                   
              return view
}

                

                export { CreateForm,Controller }