import React, { Component } from 'react'
import * as Service from '../services/Create'
import './Home.css'





export default class Clientes extends Component {
  
        constructor(props) {
        super(props)
  
        this.state = {
            Form: <Service.Controller action="showForm" page="Clientes" />,
            Result: "",
            TableList: "",
           
        }
      
    
    }
   


    render() {
     
        
        return (
            
            <div className="container mt-5 text-start">
                <div className="row">
                    <div className='col-lg-6 col-sm-12'>
                    {this.state.Form}
                    </div>
                    <div className='col-lg-6 col-sm-12 d-none'>
                    {this.state.Result}
                    </div>

                </div>

                        
            </div>
           


        )
    }
}
