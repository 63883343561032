import React, { Component } from 'react'
import './Home.css'


export default class NotFound extends Component {
  
    componentDidMount() {
   
    }

    render() {
        return (
            <div className="container-fluid">
                not found
            </div>
    


        )
    }
}
