import  { API }  from '../config';
import axios from 'axios';

async function signIn(user, pass) {
 const formData = new FormData();
 const url = API.URL;
 let response;
 
 formData.append('action','Auth');
 formData.append('user', user);
 formData.append('pass', pass);


 await axios.post(url, formData, {})
     .then(res => {
          response = res.data;
          
     })

return response;
   

}

export { signIn } 