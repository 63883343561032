
    
const REQUEST = {
     TYPE_JSON: 'application/json',
     METHOD_POST: 'post',
     METHOD_GET: 'get',
     METHOD_PUT: 'put',
     MODE: 'cors'
}
const API = {
    URL: 'http://localhost/~rosendoyanez/matrix2-master/backend/'
}


export {REQUEST, API}



