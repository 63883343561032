import React,{useState} from 'react';
import * as Service from '../services/Create'

        function setHeader(props){

            const SubheaderData = {
                "Empresas" : {
                    "titulo": "Empresas",
                    "descripcion":  "Este modulo permite crear, modificar y eliminar empresas. Con las empresas se agrupan usuarios de la aplicación dígase que en la pantalla de reportes las evaluaciones de riesgo pueden ver lo que hicieron todos los usuarios que pertenezcan a la misma empresa.",
                    "boton":  "Crear Empresa"
                },
                "Usuarios" : {
                    "titulo": "Usuarios",
                    "descripcion":  "Este modulo permite crear, modificar y eliminar empresas. Con las empresas se agrupan usuarios de la aplicación dígase que en la pantalla de reportes las evaluaciones de riesgo pueden ver lo que hicieron todos los usuarios que pertenezcan a la misma empresa.",
                    "boton":  "Crear Usuario"
                },
                 "Formularios" : {
                    "titulo": "Formularios",
                    "descripcion":  "Este modulo permite crear, modificar y eliminar empresas. Con las empresas se agrupan usuarios de la aplicación dígase que en la pantalla de reportes las evaluaciones de riesgo pueden ver lo que hicieron todos los usuarios que pertenezcan a la misma empresa.",
                    "boton":  "Crear Formularios"
                },
                 "Preguntas" : {
                    "titulo": "Preguntas",
                    "descripcion":  "Este modulo permite crear, modificar y eliminar empresas. Con las empresas se agrupan usuarios de la aplicación dígase que en la pantalla de reportes las evaluaciones de riesgo pueden ver lo que hicieron todos los usuarios que pertenezcan a la misma empresa.",
                    "boton":  "Crear Pregunta"
                },
                 "Respuestas" : {
                    "titulo": "Respuestas",
                    "descripcion":  "Este modulo permite crear, modificar y eliminar empresas. Con las empresas se agrupan usuarios de la aplicación dígase que en la pantalla de reportes las evaluaciones de riesgo pueden ver lo que hicieron todos los usuarios que pertenezcan a la misma empresa.",
                    "boton":  "Crear Respuestas"
                },
                "SujetoObligado" : {
                    "titulo": "Sujeto Obligado",
                    "descripcion":  "Este modulo permite crear, modificar y eliminar empresas. Con las empresas se agrupan usuarios de la aplicación dígase que en la pantalla de reportes las evaluaciones de riesgo pueden ver lo que hicieron todos los usuarios que pertenezcan a la misma empresa.",
                    "boton":  "Crear Sujeto Obligado"
                },
                }    

            
            const {titulo,descripcion,boton} = SubheaderData[props.page]
            const header = (el) =>(
                       
                        <div className="card text-start">
                            <div className="card-body">
                                <h5 className="card-title mb-2">{titulo}</h5>
                                <hr></hr>
                                <h6 className="card-subtitle mb-2 text-muted text-justify">{descripcion}</h6>
                                <a href="#create" onClick={() => setView(Service.CreateForm(titulo))} className="d-none card-link btn orangeICGS mt-2">{boton}</a>
                            </div>
                        </div>
                 
                      
                            )
                 
            const [view, setView] = useState(header(SubheaderData[props.page]));
           

                            return view


                            }

                
export { setHeader }